var size;
var size2;

var color = '';
var lastColor = '';
var mode = '';
var lastMode = '';

setSize();

function setSize() {
    size = $('.rangeInput').val();

    $('.dummyText').css("font-size", size + 'px');
    setTimeout(setSize, 60);
    $('#rangeValue').text(size);


    if (size != size2) {
        function h(e) {
            $(e).css({ 'height': '1', 'overflow-y': 'hidden' }).height(e.scrollHeight / 1);

        }
        $('textarea').each(function () {
            h(this);
        }).on('input', function () {
            h(this);
        });
    }


    size2 = size;



}


if ($(window).width() < 624) {
    $('.rangeInput').val(48);
    $('.rangeInput').attr({ 'max': 66, 'min': 30 });
}




$('.item-select.color').click(function () {
    $('.select.color').toggleClass('active');
});

$('.item-select.mode').click(function () {
    $('.select.mode').toggleClass('active');
});

$('.item.color').click(function () {

    $('.item.color').removeClass('active');
    $(this).addClass('active');


    $('.item-select.color').removeClass(lastColor);
    $('.tx-fontswatch').removeClass(lastColor);
    $('body').removeClass(lastColor);
    color = $(this).data('color');

    var c2;

    if (color == "blue") {
        c2 = "Forest";
    }

    if (color == "red") {
        c2 = "Coral";
    }

    if (color == "black") {
        c2 = "black";
    }

    $('#colorName').text(c2);

    $('.item-select.color').addClass(color);
    $('.tx-fontswatch').addClass(color);
    $('body').addClass(color);

    lastColor = color;

    $('.select.color').toggleClass('active');




});


$('.item.mode').click(function () {

    $('.item.mode').removeClass('active');
    $(this).addClass('active');

    $('.item-select.mode').removeClass(lastMode);
    $('.tx-fontswatch').removeClass(lastMode);
    $('body').removeClass(lastMode);
    mode = $(this).data('mode');

    $('#modeName').text(mode);

    $('.item-select.mode').addClass(mode);
    $('.tx-fontswatch').addClass(mode);
    $('body').addClass(mode);
    lastMode = mode;

    $('.select.mode').toggleClass('active');





});

// $('.dummyText').click(function () {
//     $this = $(this);
//     if ($this.hasClass('active')) {
//         $this.val('');
//         $this.removeClass('active');
//     }
// });


// $('.dummyText').each(function () {
//     $this = $(this);
//     $this.focusout(function () {
//         if ($this.val() == '') {
//             var title = $this.data('title');
//             $this.val(title);
//             $this.addClass('active');
//         }
//     });
// });

// $('.dummyText').each(function () {
//     $this = $(this);

//     $this.keyup(function() {
//         $this = $(this);
//         $this.val();
//         $('.dummyText').each(function () {
//             $(this).text($this.val());
//         });

//      });



// });


$('.dummyText').keyup(function () {
    $this = $(this);
    $this.val();
    $('.dummyText').val($this.val());

    $this.focusout(function () {
        if ($this.val() == '') {
            fillDummy();
        }

        if($this.val() == $this.data('title')){
            fillDummy();
        }
    });

    
});

function fillDummy() {
    $('.dummyText').each(function () {
        $this = $(this);
        var title = $this.data('title');
        $this.val(title);
    });
}


var controller = new ScrollMagic.Controller();
var controller2 = new ScrollMagic.Controller();

$('.tx-fontswatch').each(function () {


    if ($(window).width() < 624) {
        var height = window.innerHeight ? window.innerHeight : $(window).height();
        //$(this).height(height);
    };


    $this = $(this);
    $duration = $(this).height();
    $duration = $duration - 200;

    var scene = new ScrollMagic.Scene({ triggerElement: this, offset: 0, triggerHook: 0 })
        //.addIndicators()
        .setClassToggle(this, 'active')
        .addTo(controller);

    var scene2 = new ScrollMagic.Scene({ triggerElement: this, offset: 0, triggerHook: 0 })
        //.addIndicators()
        .setClassToggle(this, 'button')
        .addTo(controller);

});

$('.trigger-modalbox').click(function () {
    $('.modalbox').addClass('active');
});

$('.modalbox').click(function () {
    $('.modalbox').removeClass('active');
});

$(document).keyup(function (e) {
    if (e.keyCode === 27) {
        $('.modalbox').removeClass('active');
    }
});


$(function () {
    if ($(".DCE_fontsControll").length) {

        var content = $(".DCE_fontsControll .content").text();
        var inverted = $(".DCE_fontsControll .content").attr('data-inverted');

        console.log(inverted);

        if (inverted == 1) {

            lastMode = 'inverted';

            $('body').addClass("inverted");
            $('.tx-fontswatch').addClass("inverted");
            $('.item-select.selected.mode').addClass("inverted");
            $('.item.mode.inverted').addClass("active");
            $('.item.mode.regular').removeClass('active');
            $("#modeName").text('inverted');
        }

        $(".modalbox .content").append(content);
    }

    function h(e) {
        //$(e).css({ 'height': '60', 'overflow-y': 'hidden' }).height(e.scrollHeight / 1);
        $('textarea').css({ 'height': '60', 'overflow-y': 'hidden' }).height(e.scrollHeight / 1);

    }
    $('textarea').each(function () {
        h(this);
    }).on('input', function () {
        h(this);
    });

});



